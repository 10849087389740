<template>
  <div>
    <v-container fluid>
      <v-row>
        <v-col>
          <v-data-table
            class="pa-2"
            :headers="headers"
            :items="recipes"
            item-key="keyValue"
            :items-per-page="itemsPerPage"
            hide-default-footer
            fixed-header
            dense
            show-expand
            :loading="loading"
            loading-text="Loading all data....please wait"
            must-sort
            sort-by="favourite"
            sort-desc
            :search="search"
          >
            <template v-slot:top>
              <v-toolbar height="80" flat>
                <v-toolbar-title
                  >Recipes ({{ recipes.length }})</v-toolbar-title
                >
                <v-spacer></v-spacer>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                  clearable
                ></v-text-field>
                <v-spacer></v-spacer>
                <v-btn
                  :disabled="loading || saving"
                  icon
                  color="primary"
                  @click="Refresh()"
                  ><v-icon>mdi-refresh</v-icon></v-btn
                >
                <v-btn
                  :disabled="loading || saving"
                  fab
                  small
                  color="primary"
                  @click="NewRecipe()"
                  ><v-icon>mdi-plus</v-icon></v-btn
                >
              </v-toolbar>
            </template>
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length" class="amber lighten-4 pa-2">
                <b>METHOD</b><br />{{ item.method }}
              </td>
            </template>
            <template v-slot:item.favourite="{ item }">
              <v-icon
                small
                class="mr-2"
                color="primary"
                @click="ToggleFavourite(item)"
              >
                {{ item.favourite ? "mdi-heart" : "mdi-heart-outline" }}
              </v-icon>
            </template>
            <template v-slot:item.name="{ item }">
              <div class="primary--text row-pointer" @click="EditRecipe(item)">
                {{ item.name }}
              </div>
            </template>
            <template v-slot:item.yield="{ item }">
              <span>{{ formatNumber(item.yield, 2) }}</span>
            </template>
            <template v-slot:item.weight="{ item }">
              <span>{{ formatNumber(item.weight, 2) }} Kg</span>
            </template>
            <template v-slot:item.unitWeight="{ item }">
              <span>{{ formatNumber(item.weight / item.yield, 3) }} Kg</span>
            </template>
            <template v-slot:item.cost="{ item }">
              <span>{{ formatCurrency(item.cost, 2) }}</span>
            </template>
            <template v-slot:item.kgCost="{ item }">
              <span>{{ formatCurrency(item.kgCost, 2) }}</span>
            </template>
            <template v-slot:item.unitCost="{ item }">
              <b>{{ formatCurrency(item.unitCost, 2) }}</b>
            </template>
            <template v-slot:item.gp="{ item }">
              <span>{{ formatPercent(item.gp, 2) }}</span>
            </template>
            <template v-slot:item.packagingCost="{ item }">
              <span>{{ formatCurrency(item.packagingCost, 2) }}</span>
            </template>
            <template v-slot:item.packagingGp="{ item }">
              <span>{{ formatPercent(item.packagingGp, 2) }}</span>
            </template>
            <template v-slot:item.price="{ item }">
              <span
                ><b>{{ formatCurrency(item.price, 2) }}</b></span
              >
            </template>
            <template v-slot:item.profit="{ item }">
              <span>{{
                formatCurrency(
                  item.price - item.unitCost - item.packagingCost,
                  2
                )
              }}</span>
            </template>
            <template v-slot:item.used="{ item }">
              <span>{{
                $store.getters.UsedInRecipes(item.keyValue).length
              }}</span>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-icon
                small
                color="primary"
                class="mr-2"
                @click="CopyRecipe(item)"
              >
                mdi-content-copy
              </v-icon>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <v-row>
        <v-col> </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import utilities from "@/services/Utilities.js";
import { mapState } from "vuex";

export default {
  components: {},
  name: "Recipes",
  computed: {
    ...mapState(["recipes", "loading", "saving"])
  },
  data() {
    return {
      search: "",
      showDialog: false,
      editedIndex: -1,
      editedItem: {},
      itemsPerPage: -1,
      headers: [
        { text: "", value: "favourite" },
        { text: "Name", value: "name" },
        { text: "Cost", value: "cost", align: "end" },
        { text: "Yield", value: "yield", align: "end" },
        { text: "Unit cost", value: "unitCost", align: "end" },
        { text: "Weight", value: "weight", align: "end" },
        { text: "Kilogram cost", value: "kgCost", align: "end" },
        { text: "Unit weight", value: "unitWeight", align: "end" },
        { text: "Recipe margin %", value: "gp", align: "end" },
        { text: "Packaging cost", value: "packagingCost", align: "end" },
        { text: "Packaging margin %", value: "packagingGp", align: "end" },
        { text: "Unit price", value: "price", align: "end" },
        { text: "Profit", value: "profit", align: "end", sortable: false },
        {
          text: "Used in recipes",
          value: "used",
          align: "end",
          sortable: false
        },
        { text: "Actions", value: "actions", sortable: false }
      ]
    };
  },
  methods: {
    async ToggleFavourite(item) {
      this.editedItem = JSON.parse(JSON.stringify(item));
      if (!("favourite" in this.editedItem)) {
        this.$set(this.editedItem, "favourite", false);
      }
      this.editedItem.favourite = !this.editedItem.favourite;
      this.editedItem = await this.$store.dispatch(
        "SaveRecipe",
        this.editedItem
      );
    },
    EditRecipe(item) {
      this.$store.state.recipe = item;
      this.$router.push("/recipes/recipe");
    },
    async Refresh() {
      await this.$store.dispatch("LoadData", true);
    },
    CopyRecipe(item) {
      let copy = JSON.parse(JSON.stringify(item));
      copy.name += " (copy)";
      copy.keyValue = "TempKey";
      this.$store.state.recipe = copy;
      this.$router.push("/recipes/recipe");
    },
    NewRecipe: function() {
      this.$store.state.recipe = {
        kgCost: 0.0,
        yield: 0,
        packagingGp: 0.1,
        keyValue: "TempKey",
        gp: 0.0,
        ingredients: [],
        name: "New Recipe",
        method: "",
        weight: 0.0,
        cost: 0.0,
        packagingCost: 0,
        unitCost: 0.0,
        itemType: "recipe",
        price: 0.0
      };
      this.$router.push("/recipes/recipe");
    },
    formatNumber(value, fractionDigits = 2) {
      return utilities.formatNumber(value, fractionDigits);
    },
    formatCurrency(value, fractionDigits = 2) {
      return utilities.formatCurrency(value, fractionDigits);
    },
    formatPercent(value, fractionDigits = 2) {
      return utilities.formatPercent(value, fractionDigits);
    }
  }
};
</script>
<style lang="css" scoped>
.row-pointer {
  cursor: pointer;
}
</style>
