var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"md":"12"}},[_c('v-toolbar',{attrs:{"height":"80","flat":""}},[(!_vm.printFriendly)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"pa-1",attrs:{"color":"primary","disabled":_vm.previousRecipe.name === 'At first recipe' || _vm.isDirty},on:{"click":function($event){return _vm.GoToRecipe(_vm.previousRecipe.keyValue)}}},'v-icon',attrs,false),on),[_vm._v("mdi-arrow-left")])]}}],null,false,1607846288)},[_c('span',[_vm._v(_vm._s(_vm.previousRecipe.name))])]):_vm._e(),(!_vm.printFriendly)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-autocomplete',_vm._g(_vm._b({staticClass:"pl-3 mb-n7 body-2",attrs:{"disabled":_vm.isDirty || _vm.saving,"dense":"","label":"Select recipe","items":_vm.recipes,"item-text":"name","item-key":"keyValue","return-object":""},on:{"change":_vm.Reset},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('v-icon',{attrs:{"small":""}},[_vm._v(" "+_vm._s(item.favourite ? "mdi-heart" : "mdi-heart-outline")+" ")]),_vm._v(" "+_vm._s(item.name)+" ")],1)]}}],null,true),model:{value:(_vm.$store.state.recipe),callback:function ($$v) {_vm.$set(_vm.$store.state, "recipe", $$v)},expression:"$store.state.recipe"}},'v-autocomplete',attrs,false),on))]}}],null,false,3976928870)},[_c('span',[_vm._v("Click here to select another recipe")])]):_vm._e(),(!_vm.printFriendly)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"pa-1",attrs:{"color":"primary","disabled":_vm.nextRecipe.name === 'At last recipe' || _vm.isDirty},on:{"click":function($event){return _vm.GoToRecipe(_vm.nextRecipe.keyValue)}}},'v-icon',attrs,false),on),[_vm._v("mdi-arrow-right")])]}}],null,false,3879163291)},[_c('span',[_vm._v(_vm._s(_vm.nextRecipe.name))])]):_vm._e(),(!_vm.printFriendly)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"pa-1",attrs:{"color":"primary","disabled":!_vm.isDirty || _vm.saving || !_vm.isValid},on:{"click":function($event){return _vm.Save()}}},'v-icon',attrs,false),on),[_vm._v("mdi-content-save")])]}}],null,false,1388180873)},[_c('span',[_vm._v("Save changes")])]):_vm._e(),(!_vm.printFriendly)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"pa-1",attrs:{"color":"primary","disabled":!_vm.isDirty || _vm.saving},on:{"click":function($event){return _vm.Reset()}}},'v-icon',attrs,false),on),[_vm._v("mdi-undo")])]}}],null,false,1026380005)},[_c('span',[_vm._v("Undo changes")])]):_vm._e(),(_vm.printFriendly)?_c('v-spacer'):_vm._e(),(_vm.printFriendly)?_c('div',{staticClass:"title font-weight-bold primary--text row-pointer",on:{"click":function($event){return _vm.ToggleState()}}},[_vm._v(" "+_vm._s(_vm.editedItem.name)+" ")]):_vm._e(),(!_vm.printFriendly)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"pa-1",attrs:{"color":"primary","disabled":_vm.editedItem.keyValue === 'TempKey' ||
                    _vm.saving ||
                    !_vm.isValid ||
                    _vm.isDirty},on:{"click":function($event){return _vm.EditRecipe()}}},'v-icon',attrs,false),on),[_vm._v("mdi-baguette")])]}}],null,false,694714783)},[_c('span',[_vm._v("Edit recipe")])]):_vm._e(),(!_vm.printFriendly)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"pa-1",attrs:{"color":"primary","disabled":_vm.editedItem.keyValue === 'TempKey' || _vm.saving || !_vm.isValid},on:{"click":function($event){return _vm.ToggleState()}}},'v-icon',attrs,false),on),[_vm._v("mdi-printer")])]}}],null,false,565005883)},[_c('span',[_vm._v("Show printer friendly view")])]):_vm._e(),_c('v-spacer'),(_vm.saving)?_c('v-progress-circular',{staticClass:"pl-3",attrs:{"indeterminate":_vm.saving,"size":"24","color":"primary"}}):_vm._e()],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"md":"12"}},[_c('v-data-table',{staticClass:"pa-2",attrs:{"dense":"","items":_vm.editedItem.ingredients,"headers":_vm.GetHeaders(),"items-per-page":_vm.itemsPerPage,"item-text":"name","item-key":"keyValue","hide-default-footer":"","disable-sort":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"height":"80","flat":""}},[(!_vm.printFriendly)?_c('div',{staticClass:"title font-weight-bold"},[_vm._v(" "+_vm._s(_vm.editedItem.name)+" ")]):_vm._e(),(!_vm.printFriendly)?_c('v-spacer'):_vm._e(),(!_vm.printFriendly)?_c('v-spacer'):_vm._e(),(!_vm.printFriendly)?_c('v-text-field',{staticClass:"body-2",attrs:{"outlined":"","label":"Other yield","dense":"","suffix":"unit(s)","rules":[_vm.rules.notNegative]},on:{"change":function($event){return _vm.OtherWeight()}},model:{value:(_vm.editedItem.otherYield),callback:function ($$v) {_vm.$set(_vm.editedItem, "otherYield", $$v)},expression:"editedItem.otherYield"}}):_vm._e()],1)]},proxy:true},{key:"header.name",fn:function(ref){
                    var header = ref.header;
return [_c('div',{staticClass:"body-1 font-weight-bold"},[_vm._v(" "+_vm._s(header.text)+" ")])]}},{key:"header.itemType",fn:function(ref){
                    var header = ref.header;
return [_c('div',{staticClass:"body-1 font-weight-bold"},[_vm._v(" "+_vm._s(header.text)+" ")])]}},{key:"header.quantity",fn:function(ref){
                    var header = ref.header;
return [_c('div',{staticClass:"body-1 font-weight-bold"},[_vm._v(" "+_vm._s(header.text)+" ")])]}},{key:"header.quantity2",fn:function(ref){
                    var header = ref.header;
return [_c('div',{staticClass:"body-1 font-weight-bold"},[_vm._v(" "+_vm._s(header.text)+" ")])]}},{key:"item.itemType",fn:function(ref){
                    var item = ref.item;
return [_c('v-icon',[_vm._v(_vm._s(_vm.GetIcon(item.itemType)))])]}},{key:"item.name",fn:function(ref){
                    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.name))])]}},{key:"item.quantity",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatNumber(item.quantity, 3))+" "+_vm._s(item.itemType === "packaging" ? "unit(s)" : "Kg")+" ")]}},{key:"item.quantity2",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatNumber(_vm.OtherQuantity(item), 3))+" "+_vm._s(item.itemType === "packaging" ? "unit(s)" : "Kg")+" ")]}},{key:"item.lineTotal",fn:function(ref){
                    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatCurrency(item.lineTotal, 2)))])]}},{key:"footer",fn:function(){return [_c('hr'),_c('v-textarea',{staticClass:"body-2 pt-5",attrs:{"placeholder":"Enter the recipe method here","dense":"","label":"Method","outlined":""},on:{"change":function($event){_vm.isDirty = true}},model:{value:(_vm.editedItem.method),callback:function ($$v) {_vm.$set(_vm.editedItem, "method", $$v)},expression:"editedItem.method"}}),_c('v-data-table',{attrs:{"headers":_vm.footerHeaders,"items":[_vm.editedItem],"item-key":"keyValue","items-per-page":_vm.itemsPerPage,"hide-default-footer":"","fixed-header":"","disable-sort":"","dense":""},scopedSlots:_vm._u([{key:"item.yield",fn:function(ref){
                    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatNumber(item.yield, 2))+" unit(s)")])]}},{key:"item.weight",fn:function(ref){
                    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatNumber(item.weight, 2))+" kg")])]}},{key:"item.otherYield",fn:function(ref){
                    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatNumber(item.otherYield, 2))+" unit(s)")])]}},{key:"item.otherWeight",fn:function(ref){
                    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatNumber(item.otherWeight, 2))+" kg")])]}}])})]},proxy:true}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }