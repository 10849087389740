var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('v-data-table',{staticClass:"pa-2",attrs:{"headers":_vm.headers,"items":_vm.packaging,"item-key":"keyValue","items-per-page":_vm.itemsPerPage,"hide-default-footer":"","fixed-header":"","must-sort":"","sort-by":"name","dense":"","loading":_vm.loading,"loading-text":"Loading all data....please wait","search":_vm.search},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"height":"80","flat":""}},[_c('v-toolbar-title',[_vm._v("Packaging ("+_vm._s(_vm.packaging.length)+")")]),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":"","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('v-btn',{attrs:{"disabled":_vm.loading,"icon":"","color":"primary"},on:{"click":function($event){return _vm.refresh()}}},[_c('v-icon',[_vm._v("mdi-refresh")])],1),_c('v-btn',{attrs:{"disabled":_vm.loading,"fab":"","small":"","color":"primary"},on:{"click":function($event){return _vm.NewItem()}}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1)]},proxy:true},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"primary--text row-pointer",on:{"click":function($event){return _vm.EditItem(item)}}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.units",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatNumber(item.units, 2)))])]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatCurrency(item.price, 2)))])]}},{key:"item.unitPrice",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatCurrency(item.unitPrice, 2)))])]}},{key:"item.used",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.UsedInRecipes(item.keyValue).length))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"color":"primary","small":"","disabled":_vm.UsedInRecipes(item.keyValue).length > 0},on:{"click":function($event){return _vm.DeleteItem(item)}}},[_vm._v(" mdi-delete-outline ")])]}}])})],1)],1)],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"400"},model:{value:(_vm.deleteDialog),callback:function ($$v) {_vm.deleteDialog=$$v},expression:"deleteDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"body-1"},[_vm._v("Delete \""+_vm._s(_vm.editedItem.name)+"\" ?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"small":"","color":"primary","disabled":_vm.saving},on:{"click":function($event){return _vm.DeleteConfirm()}}},[_vm._v("Yes")]),_c('v-btn',{attrs:{"small":"","color":"primary","disabled":_vm.saving},on:{"click":function($event){_vm.deleteDialog = false}}},[_vm._v("No")])],1)],1)],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"600"},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c('PackagingType',{attrs:{"editedItem":_vm.editedItem,"editedIndex":_vm.editedIndex},on:{"Save":function($event){_vm.showDialog = false},"Cancel":function($event){_vm.showDialog = false}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }