<template>
  <div>
    <v-container fluid>
      <v-row>
        <v-col md="12"
          ><v-toolbar height="80" flat>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  class="pa-1"
                  v-bind="attrs"
                  v-on="on"
                  color="primary"
                  :disabled="
                    previousRecipe.name === 'At first recipe' || isDirty
                  "
                  @click="GoToRecipe(previousRecipe.keyValue)"
                  >mdi-arrow-left</v-icon
                >
              </template>
              <span>{{ previousRecipe.name }}</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-autocomplete
                  dense
                  :disabled="isDirty || saving"
                  v-bind="attrs"
                  v-on="on"
                  label="Select recipe"
                  class="pl-3 mb-n7 body-2"
                  @change="Reset"
                  v-model="$store.state.recipe"
                  :items="recipes"
                  item-text="name"
                  item-key="keyValue"
                  return-object
                >
                  <template v-slot:item="{ item }">
                    <div>
                      <v-icon small>
                        {{ item.favourite ? "mdi-heart" : "mdi-heart-outline" }}
                      </v-icon>
                      {{ item.name }}
                    </div>
                  </template>
                </v-autocomplete>
              </template>
              <span>Click here to select another recipe</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  class="pa-1"
                  v-bind="attrs"
                  v-on="on"
                  color="primary"
                  :disabled="nextRecipe.name === 'At last recipe' || isDirty"
                  @click="GoToRecipe(nextRecipe.keyValue)"
                  >mdi-arrow-right</v-icon
                >
              </template>
              <span>{{ nextRecipe.name }}</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  class="pa-1"
                  color="primary"
                  v-bind="attrs"
                  v-on="on"
                  :disabled="!isDirty || !isValid || saving"
                  @click="Save()"
                  >mdi-content-save</v-icon
                >
              </template>
              <span>Save changes</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  class="pa-1"
                  color="primary"
                  v-bind="attrs"
                  v-on="on"
                  :disabled="!isDirty || saving"
                  @click="Reset()"
                  >mdi-undo</v-icon
                ></template
              >
              <span>Undo changes</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  class="pa-1"
                  color="primary"
                  @click="Delete()"
                  v-bind="attrs"
                  v-on="on"
                  :disabled="
                    editedItem.keyValue === 'TempKey' ||
                      saving ||
                      UsedInRecipes.length > 0 ||
                      isDirty
                  "
                  >mdi-delete-outline</v-icon
                >
              </template>
              <span>Delete</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  class="pa-1"
                  color="primary"
                  @click="ViewRecipePage()"
                  v-bind="attrs"
                  v-on="on"
                  :disabled="
                    editedItem.keyValue === 'TempKey' || saving || isDirty
                  "
                  >mdi-book-open-page-variant-outline</v-icon
                >
              </template>
              <span>View recipe in recipe book</span>
            </v-tooltip>

            <v-select
              dense
              single-line
              :label="
                UsedInRecipes.length > 0
                  ? 'Used in ' + UsedInRecipes.length + ' other recipe(s)'
                  : 'Not used in other recipes'
              "
              no-data-text="Not used in other recipes"
              filled
              class="pl-3 mb-n7 body-2"
              @change="Reset"
              v-model="$store.state.recipe"
              :items="UsedInRecipes"
              item-text="name"
              item-key="keyValue"
              return-object
            ></v-select>

            <v-spacer></v-spacer>
            <v-progress-circular
              v-if="saving"
              :indeterminate="saving"
              size="24"
              color="primary"
              class="pl-3"
            ></v-progress-circular></v-toolbar
        ></v-col>
      </v-row>
      <v-row>
        <v-col md="3">
          <v-data-table
            class="pa-2"
            dense
            height="590"
            show-select
            :items="items"
            :headers="headers"
            :items-per-page="itemsPerPage"
            item-text="name"
            item-key="keyValue"
            v-model="editedItem.ingredients"
            hide-default-footer
            disable-sort
            @item-selected="IngredientSelected"
          >
            <template v-slot:top>
              <v-toolbar height="80" flat>
                <v-toolbar-title>{{ ingType }}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn-toggle dense mandatory>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        @click="ingType = 'Ingredients'"
                        ><v-icon color="primary">mdi-spoon-sugar</v-icon></v-btn
                      >
                    </template>
                    <span>Show ingredients</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        @click="ingType = 'Recipes'"
                        ><v-icon color="primary">mdi-baguette</v-icon></v-btn
                      >
                    </template>
                    <span>Show recipes</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        @click="ingType = 'Packaging'"
                        ><v-icon color="primary"
                          >mdi-package-variant</v-icon
                        ></v-btn
                      >
                    </template>
                    <span>Show packaging</span>
                  </v-tooltip>
                </v-btn-toggle>
              </v-toolbar>
              <v-btn
                fab
                small
                color="primary"
                right
                :disabled="ingType != 'Ingredients'"
                @click="NewIngredient()"
                ><v-icon>mdi-plus</v-icon></v-btn
              >
            </template>
          </v-data-table>
        </v-col>
        <v-col md="9">
          <v-data-table
            class="pa-2"
            dense
            height="444"
            :items="editedItem.ingredients"
            :headers="selectedHeaders"
            :items-per-page="itemsPerPage"
            item-text="name"
            item-key="keyValue"
            hide-default-footer
            disable-sort
          >
            <template v-slot:top>
              <v-toolbar height="80" flat>
                <v-icon class="mt-n7 pr-2">
                  {{ editedItem.favourite ? "mdi-heart" : "mdi-heart-outline" }}
                </v-icon>
                <v-text-field
                  class="body-2 font-weight-bold"
                  outlined
                  label="Name"
                  ref="recipeName"
                  dense
                  v-model="editedItem.name"
                  @change="IsValid()"
                  :rules="[rules.notEmpty]"
                ></v-text-field>
                <v-spacer></v-spacer>

                <v-spacer></v-spacer>
                <v-text-field
                  class="body-2"
                  outlined
                  label="Yield"
                  dense
                  v-model="editedItem.yield"
                  suffix="unit(s)"
                  @change="UpdateCosts('yield')"
                  :rules="[rules.notNegative]"
                ></v-text-field>
                <v-spacer></v-spacer>
                <v-text-field
                  class="body-2"
                  outlined
                  label="Margin"
                  dense
                  v-model="margin"
                  @change="UpdateCosts('margin')"
                  suffix="%"
                  :rules="[rules.notNegative]"
                ></v-text-field>
                <v-spacer></v-spacer>
                <v-text-field
                  class="body-2 font-weight-bold"
                  outlined
                  label="Selling price per unit"
                  dense
                  v-model="editedItem.price"
                  @change="UpdateCosts('price')"
                  prefix="R"
                  :rules="[rules.notNegative]"
                ></v-text-field>
              </v-toolbar>
            </template>

            <template v-slot:item.itemType="{ item }">
              <v-icon color="primary" @click="GoToIngredient(item)">{{
                GetIcon(item.itemType)
              }}</v-icon>
            </template>
            <template v-slot:item.name="{ item }">
              <span>{{ item.name }}</span>
            </template>
            <template v-slot:item.kgCost="{ item }">
              <span>{{ formatCurrency(item.kgCost, 2) }}</span>
            </template>
            <template v-slot:item.quantity="{ item }">
              <v-text-field
                class="mb-n6 mt-1 body-2"
                single-line
                outlined
                :suffix="item.itemType === 'packaging' ? 'unit(s)' : 'Kg'"
                dense
                v-model="item.quantity"
                @change="SaveLineItem()"
                :rules="[rules.notNegative]"
              />
            </template>
            <template v-slot:item.lineTotal="{ item }">
              <span>{{ formatCurrency(item.lineTotal, 2) }}</span>
            </template>

            <template v-slot:footer>
              <v-textarea
                height="92"
                @change="isDirty = true"
                placeholder="Enter the recipe method here"
                dense
                label="Method"
                class="body-2"
                no-resize
                outlined
                v-model="editedItem.method"
              ></v-textarea>
              <hr />

              <v-data-table
                :headers="footerHeaders"
                :items="[editedItem]"
                item-key="keyValue"
                :items-per-page="itemsPerPage"
                hide-default-footer
                fixed-header
                disable-sort
                dense
              >
                <template v-slot:item.yield="{ item }">
                  <span>{{ formatNumber(item.yield, 2) }} unit(s)</span>
                </template>
                <template v-slot:item.weight="{ item }">
                  <span>{{ formatNumber(item.weight, 2) }} kg</span>
                </template>
                <template v-slot:item.cost="{ item }">
                  <span>{{ formatCurrency(item.cost, 2) }}</span>
                </template>
                <template v-slot:item.kgCost="{ item }">
                  <span>{{ formatCurrency(item.kgCost, 2) }}</span>
                </template>
                <template v-slot:item.unitCost="{ item }">
                  <b>{{ formatCurrency(item.unitCost, 2) }}</b>
                </template>
                <template v-slot:item.gp="{ item }">
                  <span>{{ formatPercent(item.gp, 2) }}</span>
                </template>
                <template v-slot:item.packagingCost="{ item }">
                  <span>{{ formatCurrency(item.packagingCost, 2) }}</span>
                </template>
                <template v-slot:item.packagingGp="{ item }">
                  <span>{{ formatPercent(item.packagingGp, 2) }}</span>
                </template>
                <template v-slot:item.price="{ item }">
                  <span
                    ><b>{{ formatCurrency(item.price, 2) }}</b
                    ><v-icon color="primary" @click="ShowPriceCalculation(item)"
                      >mdi-information-outline</v-icon
                    ></span
                  >
                </template>
                <template v-slot:item.profit="{ item }">
                  <span>{{
                    formatCurrency(
                      item.price - item.unitCost - item.packagingCost,
                      2
                    )
                  }}</span>
                </template>
              </v-data-table>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog v-model="deleteDialog" persistent max-width="400">
      <v-card>
        <v-card-title class="body-1"
          >Delete "{{ editedItem.name }}" ?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            small
            color="primary"
            :disabled="saving"
            @click="DeleteConfirm()"
            >Yes</v-btn
          >
          <v-btn
            small
            color="primary"
            :disabled="saving"
            @click="deleteDialog = false"
            >No</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-if="showIngredientDialog"
      v-model="showIngredientDialog"
      persistent
      max-width="600"
    >
      <Ingredient
        :editedIndex="editedIngredientIndex"
        :editedItem="editedIngredient"
        @Save="IngredientSaved()"
        @Cancel="showIngredientDialog = false"
      />
    </v-dialog>
    <v-dialog
      v-if="showPackagingTypeDialog"
      v-model="showPackagingTypeDialog"
      persistent
      max-width="600"
    >
      <PackagingType
        :editedIndex="editedPackagingTypeIndex"
        :editedItem="editedPackagingType"
        @Save="PackagingTypeSaved()"
        @Cancel="showPackagingTypeDialog = false"
      />
    </v-dialog>
    <v-dialog v-model="priceCalculationShow" max-width="400">
      <v-card max-width="400">
        <v-card-title>Price calculation</v-card-title>
        <v-card-text>
          <Price :editedItem="this.editedItem" />
        </v-card-text>
        <v-card-actions
          ><v-spacer></v-spacer>
          <v-btn color="primary" @click="priceCalculationShow = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
function compare(a, b) {
  if (a.name.toLowerCase() < b.name.toLowerCase()) {
    return -1;
  }
  if (a.name.toLowerCase() > b.name.toLowerCase()) {
    return 1;
  }
  return 0;
}

import utilities from "@/services/Utilities.js";
import { mapState } from "vuex";
import Ingredient from "../components/Ingredient.vue";
import PackagingType from "../components/PackagingType";
import Price from "../components/Price";
export default {
  name: "Recipe",
  components: {
    Ingredient,
    PackagingType,
    Price
  },
  computed: {
    ...mapState([
      "ingredients",
      "recipes",
      "packaging",
      "packagingGp",
      "saving"
    ]),
    items() {
      let a = [];
      if (this.ingType === "Ingredients") {
        a = this.ingredients;
      } else if (this.ingType === "Recipes")
        a = this.recipes.filter(x => x.keyValue != this.editedItem.keyValue);
      else a = this.packaging;
      return a;
    },
    margin: {
      get: function() {
        return +(this.editedItem.gp * 100).toFixed(2);
      },
      set: function(newValue) {
        this.editedItem.gp = (newValue / 100).toFixed(3);
      }
    },
    previousRecipe() {
      if (this.editedIndex <= 0) return { name: "At first recipe" };
      else return this.recipes[this.editedIndex - 1];
    },
    nextRecipe() {
      if (
        this.editedIndex === -1 ||
        this.editedIndex === this.recipes.length - 1
      )
        return { name: "At last recipe" };
      else return this.recipes[this.editedIndex + 1];
    },
    UsedInRecipes() {
      return this.$store.getters.UsedInRecipes(this.editedItem.keyValue);
    }
  },
  data: () => ({
    priceCalculationShow: false,
    rules: {
      notNegative: v =>
        parseFloat(v) >= 0 ||
        "Value must be numbers only and cannot be negative",
      notEmpty: v => v != "" || "Value cannot be empty"
    },
    originalItem: {},
    deleteDialog: false,
    isDirty: false,
    willAffectOtherRecipes: true,
    isValid: true,
    toggle_exclusive: 0,
    editedItem: {},
    editedIndex: -1,
    editedIngredient: {},
    editedIngredientIndex: -1,
    showIngredientDialog: false,
    editedPackagingType: {},
    editedPackagingTypeIndex: -1,
    showPackagingTypeDialog: false,
    selectedItems: [],
    itemsPerPage: -1,
    ingType: "Ingredients",
    headers: [{ text: "Name", value: "name" }],
    selectedHeaders: [
      { text: "Type", value: "itemType" },
      { text: "Name", value: "name" },
      { text: "Kilogram price", value: "kgCost", align: "end" },
      { text: "Quantity", value: "quantity" },
      { text: "Line total", value: "lineTotal", align: "end" }
    ],
    footerHeaders: [
      { text: "Cost", value: "cost", align: "end" },
      { text: "Weight", value: "weight", align: "end" },
      { text: "Cost per Kg", value: "kgCost", align: "end" },
      { text: "Yield", value: "yield", align: "end" },
      { text: "Cost per unit", value: "unitCost", align: "end" },
      { text: "Recipe margin %", value: "gp", align: "end" },
      { text: "Packaging cost", value: "packagingCost", align: "end" },
      { text: "Packaging margin %", value: "packagingGp", align: "end" },
      { text: "Selling price per unit", value: "price", align: "end" },
      { text: "Profit", value: "profit", align: "end" }
    ]
  }),
  methods: {
    IsValid() {
      console.log(this.editedItem);
      console.log(this.editedItem.name != "");
      console.log(parseFloat(this.editedItem.yield) >= 0);
      console.log(this.editedItem.gp >= 0);
      console.log(parseFloat(this.editedItem.price) >= 0);

      this.isValid =
        this.editedItem.name != "" &&
        parseFloat(this.editedItem.yield) >= 0 &&
        parseFloat(this.editedItem.gp) >= 0 &&
        parseFloat(this.editedItem.price) >= 0;

      console.log(this.isValid);
      this.isDirty = true;
    },
    ViewRecipePage() {
      this.$router.push("/recipebook/page");
    },
    ShowPriceCalculation(item) {
      let unitCostGpValue = (
        item.unitCost / (1 - item.gp) -
        item.unitCost
      ).toFixed(2);

      let calc = `Cost per unit R ${item.unitCost} + <br/>
       recipe margin R ${unitCostGpValue} (${item.gp * 100}%)`;

      this.priceCalculation = calc;
      this.priceCalculationShow = true;
    },
    IngredientSaved() {
      this.Reset();
      this.isDirty = false;
      this.showIngredientDialog = false;
    },
    PackagingTypeSaved() {
      this.Reset();
      this.isDirty = false;
      this.showPackagingTypeDialog = false;
    },
    NewIngredient() {
      let item = {
        itemType: "ingredient",
        keyValue: "TempKeyIngredient",
        name: "",
        weight: 0.0,
        price: 0.0,
        kgPrice: 0.0
      };
      this.editedIngredient = JSON.parse(JSON.stringify(item));
      this.editedIngredientIndex = -1;
      this.showIngredientDialog = true;
    },
    GoToIngredient(item) {
      if (item.itemType === "recipe") this.GoToRecipe(item.keyValue);
      else if (item.itemType === "ingredient") {
        this.editedIngredient = this.ingredients.find(
          x => x.keyValue === item.keyValue
        );
        this.editedIngredientIndex = this.ingredients.indexOf(
          this.editedIngredient
        );
        this.showIngredientDialog = true;
        this.editedItem.ingredients = this.recipes.find(
          x => x.keyValue === this.editedItem.keyValue
        ).ingredients;
      } else if (item.itemType === "packaging") {
        this.editedPackagingTypeIndex = this.packaging.indexOf(item);
        this.editedPackagingType = this.packaging.find(
          x => x.keyValue === item.keyValue
        );
        this.showPackagingTypeDialog = true;
        this.editedItem.ingredients = this.recipes.find(
          x => x.keyValue === this.editedItem.keyValue
        ).ingredients;
      }
    },
    GoToRecipe(recipeId) {
      this.$store.state.recipe = this.recipes.find(
        x => x.keyValue === recipeId
      );
      this.Reset();
    },
    IngredientSelected(data) {
      this.$nextTick(() => {
        if (!data.value) {
          this.SaveLineItem();
          this.isDirty = true;
        } else {
          let recIngItem = Object.assign({}, data.item);
          if (recIngItem.itemType === "ingredient") {
            recIngItem.kgCost = recIngItem.kgPrice;
            recIngItem.quantity = 0.0;
            delete recIngItem.price;
            delete recIngItem.kgPrice;
            delete recIngItem.weight;
          } else if (recIngItem.itemType === "recipe") {
            recIngItem.quantity = 0.0;
            delete recIngItem.cost;
            delete recIngItem.gp;
            delete recIngItem.ingredients;
            delete recIngItem.method;
            delete recIngItem.packagingCost;
            delete recIngItem.packagingGp;
            delete recIngItem.price;
            delete recIngItem.unitCost;
            delete recIngItem.weight;
            delete recIngItem.yield;
          } else if (recIngItem.itemType === "packaging") {
            recIngItem.quantity = 0.0;
            recIngItem.kgCost = recIngItem.unitPrice;
            delete recIngItem.unitPrice;
            delete recIngItem.units;
          }

          this.editedItem.ingredients.pop();
          this.editedItem.ingredients.push(recIngItem);
          this.isDirty = true;
        }
      });
    },
    SaveLineItem() {
      this.$store.dispatch("UpdateLineItems", this.editedItem);
      this.$store.dispatch("UpdateUnitCost", this.editedItem);

      //for (let x of this.editedItem.Ingredients) {
      //  if (parseFloat(x.quantity) < 0) {
      //    this.isValid = false;
      //    break;
      //  }

      this.isDirty = true;
    },
    UpdateCosts(whatChanged) {
      if (whatChanged === "price") {
        this.$store.dispatch("UpdateMargin", this.editedItem);
      } else if (whatChanged === "yield") {
        this.$store.dispatch("UpdateUnitCost", this.editedItem);
      } else if (whatChanged === "margin") {
        this.$store.dispatch("UpdatePrice", this.editedItem);
      }
      if (isNaN(this.editedItem.gp)) this.editedItem.gp = 0;
      if (isNaN(this.editedItem.price)) this.editedItem.price = 0;

      this.editedItem.price = parseFloat(this.editedItem.price);

      this.isDirty = true;
      this.IsValid();
    },
    Delete() {
      this.deleteDialog = true;
    },
    async DeleteConfirm() {
      this.editedIndex = await this.$store.dispatch(
        "DeleteRecipe",
        this.editedItem
      );
      this.Reset();
      this.isDirty = false;
      this.deleteDialog = false;
    },
    async Save() {
      this.editedIndex = await this.$store.dispatch(
        "SaveRecipe",
        this.editedItem
      );
      let message = "Recipe saved successfully";

      if (this.willAffectOtherRecipes) {
        if (this.UsedInRecipes.length > 0) {
          let affectedRecipes = await this.$store.dispatch(
            "UpdateAffectedRecipes",
            this.editedItem
          );

          affectedRecipes = await this.$store.dispatch(
            "SaveRecipes",
            affectedRecipes
          );

          message +=
            " and updated " + affectedRecipes.length + " affected recipe(s)";
        }
      }
      this.$store.dispatch("ShowAlert", {
        type: "success",
        message: message,
        timeout: 2500
      });
      this.Reset();
      this.isDirty = false;
      this.willAffectOtherRecipes = true;
    },

    Reset() {
      if (this.$store.state.recipe.keyValue === "TempKey") {
        this.editedIndex = -1;
      } else {
        this.editedIndex = this.recipes.indexOf(this.$store.state.recipe);
        //in case they hit browser refresh while on recipe view mode, issue with Mounted lifecycle event between App.vue and this Recipe.vue
        if (this.editedIndex === -1) this.$router.push("/recipes");

        this.isDirty = false;
      }
      this.editedItem = JSON.parse(JSON.stringify(this.$store.state.recipe));
      this.editedItem.yield = parseFloat(this.editedItem.yield);
      this.editedItem.gp = parseFloat(this.editedItem.gp);
      this.editedItem.price = parseFloat(this.editedItem.price);

      this.editedItem.ingredients.forEach(x => {
        x.quantity = parseFloat(x.quantity).toFixed(3);
      });
      this.editedItem.ingredients.sort(compare);

      //if (!("favourite" in this.editedItem)) {
      //  this.$set(this.editedItem, "favourite", false);
      // }
    },
    GetIcon(itemType) {
      let a = "";
      if (itemType === "ingredient") {
        a = "mdi-spoon-sugar";
      } else if (itemType === "recipe") a = "mdi-baguette";
      else a = "mdi-package-variant";
      return a;
    },
    formatNumber(value, fractionDigits = 2) {
      return utilities.formatNumber(value, fractionDigits);
    },
    formatCurrency(value, fractionDigits = 2) {
      return utilities.formatCurrency(value, fractionDigits);
    },
    formatPercent(value, fractionDigits = 2) {
      return utilities.formatPercent(value, fractionDigits);
    }
  },
  mounted: function() {
    this.Reset();
  },

  beforeRouteLeave(to, from, next) {
    if (this.isDirty) {
      this.$store.dispatch("ShowAlert", {
        type: "primary",
        message: "You have changed this recipe, please save or undo first",
        timeout: 2000
      });
    } else next();

    //const answer = window.confirm(
    //  "You have unsaved changed, click OK to leave, or click Cancel and then click on Save to keep your changes!"
    //);
    //if (answer) {
    //  next();
    //} else {
    //  next(false);
    // }
    //} else next();
    //}
  }
};
</script>
