<template>
  <div>
    <v-container fluid>
      <v-row>
        <v-col md="12"
          ><v-toolbar height="80" flat>
            <v-tooltip v-if="!printFriendly" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  class="pa-1"
                  color="primary"
                  :disabled="
                    previousRecipe.name === 'At first recipe' || isDirty
                  "
                  @click="GoToRecipe(previousRecipe.keyValue)"
                  >mdi-arrow-left</v-icon
                >
              </template>
              <span>{{ previousRecipe.name }}</span>
            </v-tooltip>

            <v-tooltip v-if="!printFriendly" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-autocomplete
                  v-bind="attrs"
                  v-on="on"
                  :disabled="isDirty || saving"
                  dense
                  label="Select recipe"
                  class="pl-3 mb-n7 body-2"
                  @change="Reset"
                  v-model="$store.state.recipe"
                  :items="recipes"
                  item-text="name"
                  item-key="keyValue"
                  return-object
                >
                  <template v-slot:item="{ item }">
                    <div>
                      <v-icon small>
                        {{ item.favourite ? "mdi-heart" : "mdi-heart-outline" }}
                      </v-icon>
                      {{ item.name }}
                    </div>
                  </template></v-autocomplete
                >
              </template>
              <span>Click here to select another recipe</span>
            </v-tooltip>

            <v-tooltip v-if="!printFriendly" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  class="pa-1"
                  color="primary"
                  :disabled="nextRecipe.name === 'At last recipe' || isDirty"
                  @click="GoToRecipe(nextRecipe.keyValue)"
                  >mdi-arrow-right</v-icon
                >
              </template>
              <span>{{ nextRecipe.name }}</span>
            </v-tooltip>

            <v-tooltip v-if="!printFriendly" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  class="pa-1"
                  color="primary"
                  v-bind="attrs"
                  v-on="on"
                  :disabled="!isDirty || saving || !isValid"
                  @click="Save()"
                  >mdi-content-save</v-icon
                >
              </template>
              <span>Save changes</span>
            </v-tooltip>

            <v-tooltip v-if="!printFriendly" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  color="primary"
                  class="pa-1"
                  v-bind="attrs"
                  v-on="on"
                  :disabled="!isDirty || saving"
                  @click="Reset()"
                  >mdi-undo</v-icon
                >
              </template>
              <span>Undo changes</span>
            </v-tooltip>
            <v-spacer v-if="printFriendly"></v-spacer>
            <div
              v-if="printFriendly"
              @click="ToggleState()"
              class="title font-weight-bold primary--text row-pointer"
            >
              {{ editedItem.name }}
            </div>

            <v-tooltip v-if="!printFriendly" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  color="primary"
                  @click="EditRecipe()"
                  class="pa-1"
                  v-bind="attrs"
                  v-on="on"
                  :disabled="
                    editedItem.keyValue === 'TempKey' ||
                      saving ||
                      !isValid ||
                      isDirty
                  "
                  >mdi-baguette</v-icon
                ></template
              >
              <span>Edit recipe</span>
            </v-tooltip>

            <v-tooltip v-if="!printFriendly" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  color="primary"
                  @click="ToggleState()"
                  class="pa-1"
                  v-bind="attrs"
                  v-on="on"
                  :disabled="
                    editedItem.keyValue === 'TempKey' || saving || !isValid
                  "
                  >mdi-printer</v-icon
                >
              </template>
              <span>Show printer friendly view</span>
            </v-tooltip>

            <v-spacer></v-spacer>

            <v-progress-circular
              v-if="saving"
              :indeterminate="saving"
              size="24"
              color="primary"
              class="pl-3"
            ></v-progress-circular></v-toolbar
        ></v-col>
      </v-row>
      <v-row>
        <v-col md="12">
          <v-data-table
            class="pa-2"
            dense
            :items="editedItem.ingredients"
            :headers="GetHeaders()"
            :items-per-page="itemsPerPage"
            item-text="name"
            item-key="keyValue"
            hide-default-footer
            disable-sort
          >
            <template v-slot:top>
              <v-toolbar height="80" flat>
                <div v-if="!printFriendly" class="title font-weight-bold">
                  {{ editedItem.name }}
                </div>

                <v-spacer v-if="!printFriendly"></v-spacer>

                <v-spacer v-if="!printFriendly"></v-spacer>
                <v-text-field
                  v-if="!printFriendly"
                  class="body-2"
                  outlined
                  label="Other yield"
                  dense
                  v-model="editedItem.otherYield"
                  @change="OtherWeight()"
                  suffix="unit(s)"
                  :rules="[rules.notNegative]"
                ></v-text-field>
              </v-toolbar>
            </template>
            <template v-slot:header.name="{ header }">
              <div class="body-1 font-weight-bold">
                {{ header.text }}
              </div>
            </template>

            <template v-slot:header.itemType="{ header }">
              <div class="body-1 font-weight-bold">
                {{ header.text }}
              </div>
            </template>

            <template v-slot:header.quantity="{ header }">
              <div class="body-1 font-weight-bold">
                {{ header.text }}
              </div>
            </template>

            <template v-slot:header.quantity2="{ header }">
              <div class="body-1 font-weight-bold">
                {{ header.text }}
              </div>
            </template>

            <template v-slot:item.itemType="{ item }">
              <v-icon>{{ GetIcon(item.itemType) }}</v-icon>
            </template>
            <template v-slot:item.name="{ item }">
              <span>{{ item.name }}</span>
            </template>

            <template v-slot:item.quantity="{ item }">
              {{ formatNumber(item.quantity, 3) }}
              {{ item.itemType === "packaging" ? "unit(s)" : "Kg" }}
            </template>
            <template v-slot:item.quantity2="{ item }">
              {{ formatNumber(OtherQuantity(item), 3) }}
              {{ item.itemType === "packaging" ? "unit(s)" : "Kg" }}
            </template>

            <template v-slot:item.lineTotal="{ item }">
              <span>{{ formatCurrency(item.lineTotal, 2) }}</span>
            </template>

            <template v-slot:footer>
              <hr />
              <v-textarea
                @change="isDirty = true"
                placeholder="Enter the recipe method here"
                dense
                label="Method"
                class="body-2 pt-5"
                outlined
                v-model="editedItem.method"
              ></v-textarea>

              <v-data-table
                :headers="footerHeaders"
                :items="[editedItem]"
                item-key="keyValue"
                :items-per-page="itemsPerPage"
                hide-default-footer
                fixed-header
                disable-sort
                dense
              >
                <template v-slot:item.yield="{ item }">
                  <span>{{ formatNumber(item.yield, 2) }} unit(s)</span>
                </template>
                <template v-slot:item.weight="{ item }">
                  <span>{{ formatNumber(item.weight, 2) }} kg</span>
                </template>
                <template v-slot:item.otherYield="{ item }">
                  <span>{{ formatNumber(item.otherYield, 2) }} unit(s)</span>
                </template>
                <template v-slot:item.otherWeight="{ item }">
                  <span>{{ formatNumber(item.otherWeight, 2) }} kg</span>
                </template>
              </v-data-table>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
function compare(a, b) {
  if (a.name.toLowerCase() < b.name.toLowerCase()) {
    return -1;
  }
  if (a.name.toLowerCase() > b.name.toLowerCase()) {
    return 1;
  }
  return 0;
}

import utilities from "@/services/Utilities.js";
import { mapState } from "vuex";
export default {
  name: "Recipe",

  computed: {
    ...mapState([
      "ingredients",
      "recipes",
      "packaging",
      "saving",
      "printFriendly"
    ]),

    previousRecipe() {
      if (this.editedIndex <= 0) return { name: "At first recipe" };
      else return this.recipes[this.editedIndex - 1];
    },
    nextRecipe() {
      if (
        this.editedIndex === -1 ||
        this.editedIndex === this.recipes.length - 1
      )
        return { name: "At last recipe" };
      else return this.recipes[this.editedIndex + 1];
    },
    UsedInRecipes() {
      return this.$store.getters.UsedInRecipes(this.editedItem.keyValue);
    }
  },
  data: () => ({
    rules: {
      notNegative: v => parseFloat(v) >= 0 || "Value cannot be negative"
    },
    isDirty: false,
    isValid: true,
    editedItem: {},
    editedIndex: -1,
    itemsPerPage: -1,

    footerHeaders: [
      { text: "Yield", value: "yield", align: "end" },
      { text: "Weight", value: "weight", align: "end" },
      { text: "Other yield", value: "otherYield", align: "end" },
      { text: "Other weight", value: "otherWeight", align: "end" }
    ]
  }),
  methods: {
    ToggleState() {
      this.$store.state.printFriendly = !this.$store.state.printFriendly;
    },
    GetHeaders() {
      let headers = [
        { text: "Type", value: "itemType" },
        { text: "Name", value: "name" },
        { text: "Yield: " + this.editedItem.yield, value: "quantity" },
        { text: "Yield: " + this.editedItem.otherYield, value: "quantity2" }
      ];
      return headers;
    },
    OtherQuantity(item) {
      return (
        (item.quantity / this.editedItem.yield) * this.editedItem.otherYield
      );
    },
    OtherWeight() {
      let i = 0;

      this.editedItem.ingredients.forEach(x => {
        if (x.itemType != "packaging")
          i +=
            (x.quantity / this.editedItem.yield) * this.editedItem.otherYield;
      });

      this.editedItem.otherWeight = parseFloat(i.toFixed(3));
      this.isValid = parseFloat(this.editedItem.otherYield) >= 0;
      this.isDirty = true;
    },
    GoToRecipe(recipeId) {
      this.$store.state.recipe = this.recipes.find(
        x => x.keyValue === recipeId
      );
      this.Reset();
    },
    EditRecipe() {
      this.$router.push("/recipes/recipe");
    },
    async Save() {
      this.editedIndex = await this.$store.dispatch(
        "SaveRecipe",
        this.editedItem
      );

      let message = "Recipe saved successfully";
      this.$store.dispatch("ShowAlert", {
        type: "success",
        message: message,
        timeout: 2500
      });
      this.Reset();
      this.isDirty = false;
    },

    Reset() {
      this.editedIndex = this.recipes.indexOf(this.$store.state.recipe);

      if (this.editedIndex === -1)
        //in case they hit browser refresh while on recipe view mode, issue with Mounted lifecycle event between App.vue and this Recipe.vue
        this.$router.push("/recipebook");

      this.isDirty = false;
      this.isValid = true;

      this.editedItem = JSON.parse(JSON.stringify(this.$store.state.recipe));
      this.editedItem.ingredients.forEach(x => {
        x.quantity = parseFloat(x.quantity).toFixed(3);
      });

      this.editedItem.ingredients.sort(compare);
      console.log(this.editedItem);
      if (!("otherYield" in this.editedItem)) {
        this.$set(this.editedItem, "otherYield", 0);
        if (!("otherWeight" in this.editedItem)) {
          this.$set(this.editedItem, "otherWeight", 0);
        }
      }
    },
    GetIcon(itemType) {
      let a = "";
      if (itemType === "ingredient") {
        a = "mdi-spoon-sugar";
      } else if (itemType === "recipe") a = "mdi-baguette";
      else a = "mdi-package-variant";
      return a;
    },
    formatNumber(value, fractionDigits = 2) {
      return utilities.formatNumber(value, fractionDigits);
    },
    formatCurrency(value, fractionDigits = 2) {
      return utilities.formatCurrency(value, fractionDigits);
    },
    formatPercent(value, fractionDigits = 2) {
      return utilities.formatPercent(value, fractionDigits);
    }
  },
  mounted: function() {
    this.Reset();
  },
  beforeRouteLeave(to, from, next) {
    if (this.isDirty) {
      this.$store.dispatch("ShowAlert", {
        type: "primary",
        message:
          "You have changed this recipe book page, please save or undo first",
        timeout: 2000
      });
    } else next();
  }
};
</script>
<style lang="css" scoped>
.row-pointer {
  cursor: pointer;
}
</style>
