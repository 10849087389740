<template>
  <div>
    <v-container fluid>
      <v-row>
        <v-col>
          <v-data-table
            class="pa-2"
            :headers="headers"
            :items="ingredients"
            item-key="keyValue"
            :items-per-page="itemsPerPage"
            hide-default-footer
            fixed-header
            :loading="loading"
            loading-text="Loading all data....please wait"
            must-sort
            sort-by="name"
            dense
            :search="search"
            ><template v-slot:top>
              <v-toolbar height="80" flat>
                <v-toolbar-title
                  >Ingredients ({{ ingredients.length }})</v-toolbar-title
                >
                <v-spacer></v-spacer>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                  clearable
                ></v-text-field>
                <v-spacer></v-spacer>
                <v-btn
                  :disabled="loading"
                  icon
                  color="primary"
                  @click="refresh()"
                  ><v-icon>mdi-refresh</v-icon></v-btn
                >
                <v-btn
                  :disabled="loading"
                  fab
                  small
                  color="primary"
                  @click="NewItem()"
                  ><v-icon>mdi-plus</v-icon></v-btn
                >
              </v-toolbar>
            </template>
            <template v-slot:item.name="{ item }">
              <div class="primary--text row-pointer" @click="EditItem(item)">
                {{ item.name }}
              </div>
            </template>
            <template v-slot:item.weight="{ item }">
              <span>{{ formatNumber(item.weight, 2) }} Kg</span>
            </template>
            <template v-slot:item.price="{ item }">
              <span>{{ formatCurrency(item.price, 2) }}</span>
            </template>
            <template v-slot:item.kgPrice="{ item }">
              <span>{{ formatCurrency(item.kgPrice, 2) }}</span>
            </template>
            <template v-slot:item.used="{ item }">
              <span>{{ UsedInRecipes(item.keyValue).length }}</span>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-icon
                class="mr-2"
                :disabled="UsedInRecipes(item.keyValue).length > 0"
                color="primary"
                small
                @click="DeleteItem(item)"
              >
                mdi-delete-outline
              </v-icon>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog
      v-if="deleteDialog"
      v-model="deleteDialog"
      persistent
      max-width="400"
    >
      <v-card>
        <v-card-title class="body-1"
          >Delete "{{ editedItem.name }}" ?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            small
            color="primary"
            :disabled="saving"
            @click="DeleteConfirm()"
            >Yes</v-btn
          >
          <v-btn
            small
            color="primary"
            :disabled="saving"
            @click="deleteDialog = false"
            >No</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-if="showDialog" v-model="showDialog" persistent max-width="600">
      <Ingredient
        :editedItem="editedItem"
        :editedIndex="editedIndex"
        @Save="showDialog = false"
        @Cancel="showDialog = false"
      />
    </v-dialog>
  </div>
</template>

<script>
import utilities from "@/services/Utilities.js";
import { mapState } from "vuex";

import Ingredient from "../components/Ingredient.vue";
export default {
  name: "Ingredients",
  components: {
    Ingredient
  },
  computed: {
    ...mapState(["ingredients", "recipes", "loading", "saving"])
  },
  data() {
    return {
      search: "",
      keyValue: "",
      itemsPerPage: -1,
      headers: [
        { text: "Name", value: "name" },
        { text: "Weight", value: "weight", align: "end" },
        { text: "Price", value: "price", align: "end" },
        { text: "Kilogram price", value: "kgPrice", align: "end" },
        {
          text: "Used in recipes",
          value: "used",
          align: "end",
          sortable: false
        },
        { text: "Actions", value: "actions", sortable: false }
      ],
      showDialog: false,
      editedItem: {},
      editedIndex: -1,
      deleteDialog: false
    };
  },
  methods: {
    UsedInRecipes(keyValue) {
      return this.$store.getters.UsedInRecipes(keyValue);
    },
    async refresh() {
      await this.$store.dispatch("LoadData", true);
    },
    EditRecipe() {
      //  this.showDialog = false;
      //  this.$store.state.recipe = this.recipes.find(
      //    x => (x.keyValue = this.keyValue)
      //  );
      //  this.$router.push("/recipes/recipe");
    },
    EditItem: function(item) {
      this.editedIndex = this.ingredients.indexOf(item);
      this.editedItem = JSON.parse(JSON.stringify(item));
      this.showDialog = true;
    },
    NewItem: function() {
      let item = {
        itemType: "ingredient",
        keyValue: "TempKeyIngredient",
        name: "",
        weight: 0.0,
        price: 0.0,
        kgPrice: 0.0
      };
      this.editedItem = JSON.parse(JSON.stringify(item));
      this.editedIndex = -1;
      this.showDialog = true;
    },
    DeleteItem(item) {
      this.editedItem = item;
      this.deleteDialog = true;
    },
    async DeleteConfirm() {
      this.editedIndex = await this.$store.dispatch(
        "DeleteIngredient",
        this.editedItem
      );
      this.deleteDialog = false;
    },
    formatNumber(value, fractionDigits = 2) {
      return utilities.formatNumber(value, fractionDigits);
    },
    formatCurrency(value, fractionDigits = 2) {
      return utilities.formatCurrency(value, fractionDigits);
    }
  }
};
</script>
<style lang="css" scoped>
.row-pointer {
  cursor: pointer;
}
</style>
