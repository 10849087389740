<template>
  <div>
    <v-card class="ma-0">
      <v-card-title>Package type</v-card-title>
      <v-card-text>
        <v-text-field
          class="body-2 font-weight-bold"
          outlined
          dense
          label="Name"
          v-model="editedItem.name"
          @change="IsValid()"
          :rules="[rules.notEmpty]"
          hint="The name of the packaging type"
        ></v-text-field>
        <v-text-field
          class="body-2"
          outlined
          dense
          label="Units"
          @change="UpdateUnitPrice()"
          v-model="editedItem.units"
          :rules="[rules.notNegative]"
          hint="The weight of the packaging type"
        ></v-text-field>
        <v-text-field
          class="body-2"
          outlined
          dense
          label="Price"
          @change="UpdateUnitPrice()"
          v-model="editedItem.price"
          prefix="R"
          :rules="[rules.notNegative]"
          hint="The price of the packaging type"
        ></v-text-field>
        <v-text-field
          class="body-2"
          dense
          filled
          v-model="editedItem.unitPrice"
          prefix="R"
          suffix="per unit"
          readonly
        ></v-text-field>
        <div>Used in the following recipe(s)</div>
        <v-card max-height="300">
          <v-card-text>
            <v-treeview
              dense
              item-text="name"
              item-key="keyValue"
              open-all
              :items="UsedInRecipes()"
            >
              <template v-slot:prepend="{ item }">
                <v-icon>{{ GetIcon(item.itemType) }}</v-icon>
              </template>
            </v-treeview>
          </v-card-text>
        </v-card>
      </v-card-text>
      <v-card-actions>
        <v-progress-circular
          v-if="saving"
          :indeterminate="saving"
          size="24"
          color="primary"
          class="pl-3"
        ></v-progress-circular>
        <span class="caption pa-3">{{ savingStatus }}</span>
        <v-spacer></v-spacer>
        <v-btn
          small
          color="primary"
          :disabled="!isDirty || saving || !isValid"
          @click="Save()"
          >Save</v-btn
        >
        <v-btn small color="primary" :disabled="saving" @click="Cancel()"
          >Cancel</v-btn
        >
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
//import utilities from "@/services/Utilities.js";
import { mapState } from "vuex";
export default {
  name: "PackagingType",
  props: ["editedItem", "editedIndex"],
  computed: {
    ...mapState(["saving", "savingStatus", "packaging"])
  },
  data: () => ({
    isDirty: false,
    isValid: false,
    willAffectRecipes: false,
    rules: {
      notNegative: v =>
        parseFloat(v) >= 0 ||
        "Value must be numbers only and cannot be negative",
      notEmpty: v => v != "" || "Value cannot be empty"
    }
  }),
  methods: {
    IsValid() {
      this.isValid =
        parseFloat(this.editedItem.price) >= 0 &&
        parseFloat(this.editedItem.units) >= 0 &&
        this.editedItem.name;
      this.isDirty = true;
    },
    EditRecipe(item) {
      this.$store.state.recipe = item;
      this.$router.push("/recipes/recipe");
    },
    UsedInRecipes(keyValue = this.editedItem.keyValue) {
      let tmp = this.$store.getters.UsedInRecipes(keyValue);
      let items = [];
      if (tmp.length > 0)
        tmp.forEach(x => {
          let item = x;
          const kids = this.UsedInRecipes(x.keyValue);
          if (kids.length > 0) item.children = kids;
          items.push(item);
        });
      return items;
    },
    GetIcon(itemType) {
      let a = "";
      if (itemType === "ingredient") {
        a = "mdi-spoon-sugar";
      } else if (itemType === "recipe") a = "mdi-baguette";
      else a = "mdi-package-variant";
      return a;
    },
    Cancel() {
      this.$emit("Cancel");
    },
    async Save() {
      await this.$store.dispatch("SavePackagingType", this.editedItem);
      let message = "Packaging type saved successfully";

      if (this.UsedInRecipes().length > 0) {
        let affectedRecipes = await this.$store.dispatch(
          "UpdateAffectedRecipes",
          this.editedItem
        );

        affectedRecipes = await this.$store.dispatch(
          "SaveRecipes",
          affectedRecipes
        );

        message +=
          " and updated " + affectedRecipes.length + " affected recipe(s)";
      }

      this.$store.dispatch("ShowAlert", {
        type: "success",
        message: message,
        timeout: 2500
      });
      this.isDirty = false;
      this.willAffectRecipes = false;
      this.$emit("Save");
    },
    UpdateUnitPrice() {
      this.editedItem.unitPrice = (
        parseFloat(this.editedItem.price) / parseFloat(this.editedItem.units)
      ).toFixed(2);

      if (isNaN(this.editedItem.unitPrice)) this.editedItem.unitPrice = 0.0;

      this.isDirty = true;
      this.IsValid();
      this.willAffectRecipes = true;
    }
  }
};
</script>
