var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-app',{attrs:{"id":"inspire"}},[_c('v-snackbar',{attrs:{"app":"","top":"","color":_vm.$store.state.alert.type,"timeout":_vm.$store.state.alert.timeout},model:{value:(_vm.$store.state.alert.show),callback:function ($$v) {_vm.$set(_vm.$store.state.alert, "show", $$v)},expression:"$store.state.alert.show"}},[_vm._v(" "+_vm._s(_vm.$store.state.alert.message)+" ")]),_c('v-app-bar',{attrs:{"height":"55","app":"","elevation":"0","clipped-left":"","color":"grey darken-3"}},[(!_vm.$store.state.printFriendly)?_c('div',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"disabled":!_vm.$store.state.user.loggedIn,"icon":"","to":"/"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-home")])],1)]}}],null,false,4089327215)},[_c('span',[_vm._v("Home")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"disabled":!_vm.$store.state.user.loggedIn,"icon":"","to":"/ingredients"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-spoon-sugar")])],1)]}}],null,false,4142296932)},[_c('span',[_vm._v("Ingredients")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"disabled":!_vm.$store.state.user.loggedIn,"icon":"","to":"/packaging"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-package-variant")])],1)]}}],null,false,3406493535)},[_c('span',[_vm._v("Packaging")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"disabled":!_vm.$store.state.user.loggedIn,"icon":"","to":"/recipes"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-baguette")])],1)]}}],null,false,696426218)},[_c('span',[_vm._v("Recipes")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"disabled":!_vm.$store.state.user.loggedIn,"icon":"","to":"/recipebook"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-book-open-page-variant-outline")])],1)]}}],null,false,2965837144)},[_c('span',[_vm._v("Recipe Book")])])],1):_vm._e(),_c('v-spacer'),_c('v-toolbar-title',{staticClass:"white--text"},[_vm._v("Namib Bakery Recipe Management v1.21 ")]),_c('v-spacer'),_c('span',{staticClass:"caption white--text"},[_vm._v("developed by "),_c('strong',[_vm._v("heizlogic")])]),(_vm.$store.state.user.loggedIn)?_c('div',[_c('v-avatar',[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-account")]),_vm._v(" "+_vm._s(_vm.$store.state.user.name)+" ")],1)],1):_vm._e()],1),_c('v-main',{staticClass:"grey lighten-1"},[_c('v-container',{attrs:{"fluid":""}},[_c('router-view')],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }