var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('v-data-table',{staticClass:"pa-2",attrs:{"headers":_vm.headers,"items":_vm.recipes,"item-key":"keyValue","items-per-page":_vm.itemsPerPage,"hide-default-footer":"","fixed-header":"","dense":"","show-expand":"","loading":_vm.loading,"loading-text":"Loading all data....please wait","must-sort":"","sort-by":"favourite","sort-desc":"","search":_vm.search},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"height":"80","flat":""}},[_c('v-toolbar-title',[_vm._v("Recipes ("+_vm._s(_vm.recipes.length)+")")]),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":"","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('v-btn',{attrs:{"disabled":_vm.loading || _vm.saving,"icon":"","color":"primary"},on:{"click":function($event){return _vm.Refresh()}}},[_c('v-icon',[_vm._v("mdi-refresh")])],1),_c('v-btn',{attrs:{"disabled":_vm.loading || _vm.saving,"fab":"","small":"","color":"primary"},on:{"click":function($event){return _vm.NewRecipe()}}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1)]},proxy:true},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"amber lighten-4 pa-2",attrs:{"colspan":headers.length}},[_c('b',[_vm._v("METHOD")]),_c('br'),_vm._v(_vm._s(item.method)+" ")])]}},{key:"item.favourite",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.ToggleFavourite(item)}}},[_vm._v(" "+_vm._s(item.favourite ? "mdi-heart" : "mdi-heart-outline")+" ")])]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"primary--text row-pointer",on:{"click":function($event){return _vm.EditRecipe(item)}}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.yield",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatNumber(item.yield, 2)))])]}},{key:"item.weight",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatNumber(item.weight, 2))+" Kg")])]}},{key:"item.unitWeight",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatNumber(item.weight / item.yield, 3))+" Kg")])]}},{key:"item.cost",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatCurrency(item.cost, 2)))])]}},{key:"item.kgCost",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatCurrency(item.kgCost, 2)))])]}},{key:"item.unitCost",fn:function(ref){
var item = ref.item;
return [_c('b',[_vm._v(_vm._s(_vm.formatCurrency(item.unitCost, 2)))])]}},{key:"item.gp",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatPercent(item.gp, 2)))])]}},{key:"item.packagingCost",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatCurrency(item.packagingCost, 2)))])]}},{key:"item.packagingGp",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatPercent(item.packagingGp, 2)))])]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_c('span',[_c('b',[_vm._v(_vm._s(_vm.formatCurrency(item.price, 2)))])])]}},{key:"item.profit",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatCurrency( item.price - item.unitCost - item.packagingCost, 2 )))])]}},{key:"item.used",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.$store.getters.UsedInRecipes(item.keyValue).length))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.CopyRecipe(item)}}},[_vm._v(" mdi-content-copy ")])]}}])})],1)],1),_c('v-row',[_c('v-col')],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }