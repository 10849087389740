<template>
  <v-container fluid>
    <v-row class="pt-n4" dense>
      <v-col class="d-flex justify-end">
        <v-spacer></v-spacer>
        <v-btn :disabled="loading" icon color="primary" @click="refresh()"
          ><v-icon>mdi-refresh</v-icon></v-btn
        >
      </v-col>
    </v-row>
    <v-row>
      <v-progress-linear
        indeterminate
        absolute
        top
        :active="$store.state.loading"
      />
      <v-col>
        <v-card height="200" elevation="0" to="/ingredients">
          <v-card-title class="primary--text">Ingredients</v-card-title>
          <v-card-text>
            <p>
              Total: <b>{{ ingredients.length }}</b>
            </p>

            Not in use: <b>{{ IngredientNotUsedInRecipes() }}</b>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col>
        <v-card height="200" elevation="0" to="/packaging">
          <v-card-title class="primary--text">Packaging</v-card-title>
          <v-card-text>
            <p>
              Total: <b>{{ packaging.length }}</b>
            </p>
            Not in use: <b>{{ PackagingNotUsedInRecipes() }}</b>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col>
        <v-card height="200" elevation="0" to="/recipes">
          <v-card-title class="primary--text">Recipes</v-card-title>
          <v-card-text>
            <p>
              Total: <b>{{ recipes.length }}</b>
            </p>
            Used in other recipes:
            <b>{{ RecipesUsedInRecipes() }}</b
            ><br />
            Marked as favourite: <b>{{ RecipesFavourited() }}</b>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col>
        <v-card height="200" elevation="0" to="/recipebook">
          <v-card-title class="primary--text">Recipe book</v-card-title>
          <v-card-text>
            <p>
              Total: <b>{{ recipes.length }}</b>
            </p>
            With method:
            <b>{{ RecipesWithMethod() }}</b
            ><br />
            With other yield:
            <b>{{ RecipesWithOtherYield() }}</b>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <!--<v-row>
      <v-col class="text-center">
        <h1>Happy 80th Birthday Dad!! :)</h1>
      </v-col>
    </v-row>-->
  </v-container>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Home",
  computed: {
    ...mapState(["ingredients", "packaging", "recipes", "loading", "saving"]),
  },
  data() {
    return {};
  },
  methods: {
    async refresh() {
      await this.$store.dispatch("LoadData", true);
    },
    IngredientNotUsedInRecipes() {
      let i = 0;
      this.ingredients.forEach((element) => {
        if (this.$store.getters.UsedInRecipes(element.keyValue).length === 0)
          i++;
      });

      return i;
    },
    PackagingNotUsedInRecipes() {
      let i = 0;
      this.packaging.forEach((element) => {
        if (this.$store.getters.UsedInRecipes(element.keyValue).length === 0)
          i++;
      });

      return i;
    },
    RecipesUsedInRecipes() {
      let i = 0;
      this.recipes.forEach((element) => {
        if (this.$store.getters.UsedInRecipes(element.keyValue).length > 0) i++;
      });

      return i;
    },
    RecipesFavourited() {
      return this.$store.state.recipes.filter((x) => x.favourite).length;
    },
    RecipesWithMethod() {
      return this.$store.state.recipes.filter((x) => x.method.length > 0)
        .length;
    },
    RecipesWithOtherYield() {
      return this.$store.state.recipes.filter((x) => x.otherYield > 0).length;
    },
  },
};
</script>
