<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-card elevation="0" max-width="300">
          <v-card-title class="primary--text">Please login</v-card-title>
          <v-card-text>
            <v-text-field
              v-model="credentials.email"
              outlined
              label="Email address"
            >
            </v-text-field>
            <v-text-field
              ref="passwordField"
              v-model="credentials.password"
              :type="fieldType"
              outlined
              label="Password"
            >
            </v-text-field>
            <v-checkbox
              v-model="show"
              @click="ShowPassword()"
              label="Show password"
            ></v-checkbox>
            {{ status }}
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="Login()">Login</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Home",
  data: () => ({
    credentials: { password: "", email: "" },
    show: false,
    fieldType: "password",
    status: ""
  }),
  methods: {
    async Login() {
      await this.$store.dispatch("Login", this.credentials);
      if (this.$store.state.user.loggedIn) {
        this.$router.push("/");
      } else this.status = "Error logging in";
    },
    ShowPassword() {
      if (this.show) this.fieldType = "text";
      else this.fieldType = "password";
    }
  }
};
</script>
