import axios from "axios";
import store from "../store/index.js";

const apiClient = axios.create({
  baseURL: "https://1ax3qfj0se.execute-api.ap-southeast-2.amazonaws.com",
  withCredentials: false, // This is the default
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json"
  }
});

export default {
  Login(email, password) {
    let auth = { email: email, password: password };
    let apiClient = axios.create({
      baseURL: "https://1ax3qfj0se.execute-api.ap-southeast-2.amazonaws.com",
      withCredentials: false,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: JSON.stringify(auth)
      }
    });

    let response = apiClient.get("/login");
    return response;
  },
  ValidateJwt() {
    apiClient.defaults.headers.common["Authorization"] = store.state.jwt;
    let response = apiClient.get("/login/validate");
    return response;
  },
  GetData() {
    let apiClient = axios.create({
      baseURL: "https://1ax3qfj0se.execute-api.ap-southeast-2.amazonaws.com",
      withCredentials: false,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: store.state.jwt
      }
    });
    let response = apiClient.get("/");
    return response;
  },
  NewRecipe(recipe) {
    let apiClient = axios.create({
      baseURL: "https://1ax3qfj0se.execute-api.ap-southeast-2.amazonaws.com",
      withCredentials: false,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: store.state.jwt
      }
    });
    let response = apiClient.post("/recipes", recipe);
    return response;
  },
  UpdateRecipe(recipe) {
    let apiClient = axios.create({
      baseURL: "https://1ax3qfj0se.execute-api.ap-southeast-2.amazonaws.com",
      withCredentials: false,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: store.state.jwt
      }
    });
    let response = apiClient.put("/recipes", recipe);
    return response;
  },
  DeleteRecipe(recipe) {
    let apiClient = axios.create({
      baseURL: "https://1ax3qfj0se.execute-api.ap-southeast-2.amazonaws.com",
      withCredentials: false,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: store.state.jwt
      }
    });
    let response = apiClient.delete("/recipes", { data: recipe });
    return response;
  },
  UpdateIngredient(ingredient) {
    let apiClient = axios.create({
      baseURL: "https://1ax3qfj0se.execute-api.ap-southeast-2.amazonaws.com",
      withCredentials: false,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: store.state.jwt
      }
    });
    let response = apiClient.put("/ingredients", ingredient);
    return response;
  },
  NewIngredient(ingredient) {
    let apiClient = axios.create({
      baseURL: "https://1ax3qfj0se.execute-api.ap-southeast-2.amazonaws.com",
      withCredentials: false,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: store.state.jwt
      }
    });
    let response = apiClient.post("/ingredients", ingredient);
    return response;
  },
  DeleteIngredient(ingredient) {
    let apiClient = axios.create({
      baseURL: "https://1ax3qfj0se.execute-api.ap-southeast-2.amazonaws.com",
      withCredentials: false,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: store.state.jwt
      }
    });
    let response = apiClient.delete("/ingredients", { data: ingredient });
    return response;
  },
  UpdatePackagingType(packagingType) {
    let apiClient = axios.create({
      baseURL: "https://1ax3qfj0se.execute-api.ap-southeast-2.amazonaws.com",
      withCredentials: false,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: store.state.jwt
      }
    });
    let response = apiClient.put("/packaging", packagingType);
    return response;
  },
  NewPackagingType(packagingType) {
    let apiClient = axios.create({
      baseURL: "https://1ax3qfj0se.execute-api.ap-southeast-2.amazonaws.com",
      withCredentials: false,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: store.state.jwt
      }
    });
    let response = apiClient.post("/packaging", packagingType);
    return response;
  },
  DeletePackagingType(packagingType) {
    let apiClient = axios.create({
      baseURL: "https://1ax3qfj0se.execute-api.ap-southeast-2.amazonaws.com",
      withCredentials: false,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: store.state.jwt
      }
    });
    let response = apiClient.delete("/packaging", {
      data: packagingType
    });
    return response;
  }
};
