<template>
  <table>
    <tr>
      <td align="right">Cost per unit</td>
      <td align="right">
        <b> {{ formatCurrency(editedItem.unitCost, 2) }}</b>
      </td>
    </tr>
    <tr>
      <td align="right">
        <b>+</b> {{ formatPercent(editedItem.gp, 2) }} recipe margin
      </td>
      <td align="right">
        <b>
          {{
            formatCurrency(
              editedItem.unitCost / (1 - editedItem.gp) - editedItem.unitCost,
              2
            )
          }}</b
        >
      </td>
    </tr>
    <tr>
      <td colspan="2"><hr /></td>
    </tr>
    <tr>
      <td class="body-1" align="right"><b>=</b> Recipe price per unit (a)</td>
      <td class="body-1" align="right">
        <b>
          {{ formatCurrency(editedItem.unitCost / (1 - editedItem.gp), 2) }}</b
        >
      </td>
    </tr>
    <tr>
      <td colspan="2"><hr /></td>
    </tr>
    <tr>
      <td align="right">Packaging cost</td>
      <td align="right">
        <b> {{ formatCurrency(editedItem.packagingCost, 2) }}</b>
      </td>
    </tr>
    <tr>
      <td align="right">
        <b>+</b>
        {{ formatPercent(editedItem.packagingGp, 2) }} packaging margin
      </td>
      <td align="right">
        <b>
          {{
            formatCurrency(
              editedItem.packagingCost / (1 - editedItem.packagingGp) -
                editedItem.packagingCost,
              2
            )
          }}</b
        >
      </td>
    </tr>
    <tr>
      <td colspan="2"><hr /></td>
    </tr>
    <tr>
      <td class="body-1" align="right">
        <b>=</b> Packaging price per unit (b)
      </td>
      <td class="body-1" align="right">
        <b>
          {{
            formatCurrency(
              editedItem.packagingCost / (1 - editedItem.packagingGp),
              2
            )
          }}</b
        >
      </td>
    </tr>
    <tr>
      <td colspan="2"><hr /></td>
    </tr>
    <tr>
      <td class="body-1" align="right">
        <b>Selling price per unit (a) + (b) = </b>
      </td>
      <td class="body-1" align="right">
        <b> {{ formatCurrency(editedItem.price, 2) }}</b>
      </td>
    </tr>
    <tr>
      <td colspan="2"><hr /></td>
    </tr>
    <tr>
      <td align="right">
        <b>Profit</b>
      </td>

      <td align="right">
        <b>
          {{
            formatCurrency(
              editedItem.price - editedItem.unitCost - editedItem.packagingCost,
              2
            )
          }}</b
        >
      </td>
    </tr>
  </table>
</template>

<script>
import utilities from "@/services/Utilities.js";
export default {
  name: "Price",
  props: ["editedItem"],
  methods: {
    formatNumber(value, fractionDigits = 2) {
      return utilities.formatNumber(value, fractionDigits);
    },
    formatCurrency(value, fractionDigits = 2) {
      return utilities.formatCurrency(value, fractionDigits);
    },
    formatPercent(value, fractionDigits = 2) {
      return utilities.formatPercent(value, fractionDigits);
    }
  }
};
</script>
