export default {
  formatCurrency: (value, fractionDigits = 2) => {
    if (isNaN(value)) value = 0;
    var formatter = new Intl.NumberFormat("en", {
      style: "currency",
      currency: "ZAR",
      currencyDisplay: "narrowSymbol",
      minimumFractionDigits: fractionDigits
    });
    return formatter.format(value);
  },
  formatNumber: function(value, fractionDigits = 2) {
    if (isNaN(value)) value = 0;
    var formatter = new Intl.NumberFormat("en", {
      style: "decimal",
      minimumFractionDigits: fractionDigits
    });
    return formatter.format(value);
  },
  formatPercent: function(value, fractionDigits = 2) {
    if (isNaN(value)) value = 0;
    var formatter = new Intl.NumberFormat("en", {
      style: "percent",
      minimumFractionDigits: fractionDigits
    });
    return formatter.format(value);
  }
};
