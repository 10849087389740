import Vue from "vue";
import Vuex from "vuex";
import Api from "@/services/Api.js";

Vue.use(Vuex);

function compare(a, b) {
  if (a.name.toLowerCase() < b.name.toLowerCase()) {
    return -1;
  }
  if (a.name.toLowerCase() > b.name.toLowerCase()) {
    return 1;
  }
  return 0;
}

export default new Vuex.Store({
  name: "store",
  state: {
    user: { name: "", surname: "", email: "", id: "", loggedIn: false },
    jwt: "",
    alert: { show: false, type: "success", message: "", timeout: 2000 },
    loading: false,
    saving: false,
    savingStatus: "",
    packagingGp: 0.1,
    ingredients: [],
    packaging: [],
    recipes: [],
    recipe: {},
    ingredient: {},
    editedIndex: -1,
    editedItem: {},
    printFriendly: false
  },
  mutations: {
    SET_INGREDIENTS(state, data) {
      state.ingredients = data.sort(compare);
    },
    SET_PACKAGING(state, data) {
      state.packaging = data.sort(compare);
    },
    SET_RECIPES(state, data) {
      state.recipes = data.sort(compare);
    },
    SET_EDITEDITEM(state, data) {
      state.editedItem = data.editedItem;
      state.editedIndex = data.editedIndex;
    }
  },
  getters: {
    UsedInRecipes: state => keyValue => {
      let found = state.recipes.filter(recipe =>
        recipe.ingredients.some(ingredient => ingredient.keyValue === keyValue)
      );
      return found;
    }
  },
  actions: {
    async GetLocalJwt(context) {
      if (localStorage.nbrmjwt) {
        context.state.jwt = localStorage.nbrmjwt;
        let response = await Api.ValidateJwt();
        context.state.user.loggedIn = response.data.isAuthorized;
      }
    },
    async Login(context, credentials) {
      try {
        let response = await Api.Login(credentials.email, credentials.password);
        context.state.jwt = response.data.token;
        context.state.user = response.data.user;
        context.state.user.loggedIn = true;
        localStorage.nbrmjwt = context.state.jwt;
        await context.dispatch("LoadData");
      } catch (error) {
        console.log(error);
      }
    },
    async SaveIngredient(context, ingredient) {
      console.log("Enter Ingredient SaveData for " + ingredient.name);
      context.state.savingStatus = "Saving ingredient " + ingredient.name;
      context.state.saving = true;
      let ingredientIndex = -1;

      if (ingredient.keyValue === "TempKeyIngredient") {
        //Add
        let response = await Api.NewIngredient(ingredient);
        ingredient.keyValue = response.data.id;
        context.state.ingredients.push(ingredient);
        context.state.ingredients.sort(compare);
        ingredientIndex = context.state.ingredients.indexOf(ingredient);
      } else {
        // Update
        let response = await Api.UpdateIngredient(ingredient);
        ingredient.keyValue = response.data.id;
        let tmp = context.state.ingredients.find(
          x => x.keyValue === ingredient.keyValue
        );
        ingredientIndex = context.state.ingredients.indexOf(tmp);
        ingredient = Object.assign(
          context.state.ingredients[ingredientIndex],
          ingredient
        );
        context.state.ingredients.sort(compare);
        ingredientIndex = context.state.ingredients.indexOf(ingredient);
      }

      context.state.saving = false;
      context.state.savingStatus = "";
      return ingredientIndex;
    },
    async DeleteIngredient(context, ingredient) {
      context.state.saving = true;
      await Api.DeleteIngredient(ingredient);

      let tmp = context.state.ingredients.find(
        x => x.keyValue === ingredient.keyValue
      );
      let ingredientIndex = context.state.ingredients.indexOf(tmp);

      context.state.ingredients.splice(ingredientIndex, 1);
      if (ingredientIndex > 0) ingredientIndex--;
      else ingredientIndex = 0;
      context.state.saving = false;
      context.state.alert.type = "success";
      context.state.alert.message = "Ingredient deleted successfully";
      context.state.alert.show = true;
    },
    async SavePackagingType(context, packagingType) {
      console.log("Enter Package Type SaveData for " + packagingType.name);
      context.state.savingStatus =
        "Saving packaging type " + packagingType.name;
      context.state.saving = true;
      let packagingTypeIndex = -1;

      if (packagingType.keyValue === "TempKeyPackaging") {
        //Add
        let response = await Api.NewPackagingType(packagingType);
        packagingType.keyValue = response.data.id;
        context.state.packaging.push(packagingType);
        context.state.packaging.sort(compare);
        packagingTypeIndex = context.state.packaging.indexOf(packagingType);
      } else {
        // Update
        let response = await Api.UpdatePackagingType(packagingType);
        packagingType.keyValue = response.data.id;
        let tmp = context.state.packaging.find(
          x => x.keyValue === packagingType.keyValue
        );
        packagingTypeIndex = context.state.packaging.indexOf(tmp);
        packagingType = Object.assign(
          context.state.packaging[packagingTypeIndex],
          packagingType
        );
        context.state.packaging.sort(compare);
        packagingTypeIndex = context.state.packaging.indexOf(packagingType);
      }

      context.state.saving = false;
      context.state.savingStatus = "";
      return packagingTypeIndex;
    },
    async DeletePackagingType(context, packagingType) {
      context.state.saving = true;
      await Api.DeletePackagingType(packagingType);

      let tmp = context.state.packaging.find(
        x => x.keyValue === packagingType.keyValue
      );
      let packagingTypeIndex = context.state.packaging.indexOf(tmp);

      context.state.packaging.splice(packagingTypeIndex, 1);

      context.state.saving = false;
      context.state.alert.type = "success";
      context.state.alert.message = "Packaging type deleted successfully";
      context.state.alert.show = true;
    },
    UsedInRecipes(context, keyValue) {
      let found = context.state.recipes.filter(recipe =>
        recipe.ingredients.some(ingredient => ingredient.keyValue === keyValue)
      );
      return found;
    },
    UpdateLineItems(context, recipe) {
      recipe.ingredients.forEach(x => {
        x.lineTotal = x.kgCost * x.quantity;
      });
      //Non Packaging Costs
      let tmp = recipe.ingredients.filter(x => x.itemType != "packaging");
      recipe.cost = tmp.reduce((a, b) => a + b.lineTotal, 0);
      recipe.weight = tmp.reduce((a, b) => a + parseFloat(b.quantity), 0);
      recipe.kgCost = (recipe.cost / recipe.weight).toFixed(2);
      //Packaging Costs
      tmp = recipe.ingredients.filter(x => x.itemType === "packaging");
      recipe.packagingCost = tmp.reduce((a, b) => a + b.lineTotal, 0);
      context.dispatch("UpdateUnitCost", recipe);
    },
    UpdateMargin(context, recipe) {
      recipe.gp =
        (recipe.price -
          recipe.packagingCost / (1 - recipe.packagingGp) -
          recipe.unitCost) /
        (recipe.price - recipe.packagingCost / (1 - recipe.packagingGp));
    },
    UpdateUnitCost(context, recipe) {
      recipe.unitCost = (recipe.cost / recipe.yield).toFixed(2);
      context.dispatch("UpdatePrice", recipe);
    },
    UpdatePrice(context, recipe) {
      let unitCostTotal = recipe.unitCost / (1 - recipe.gp);
      let packagingTotal = recipe.packagingCost / (1 - recipe.packagingGp);
      recipe.price = (unitCostTotal + packagingTotal).toFixed(2);
    },
    async SaveRecipes(context, recipes) {
      context.state.saving = true;
      //Remove duplicates
      let lenRecipes = recipes.length;
      let obj = {};

      for (var i = 0; i < lenRecipes; i++)
        obj[recipes[i]["keyValue"]] = recipes[i];

      recipes = new Array();
      for (var key in obj) recipes.push(obj[key]);
      //Duplicates removed
      context.state.savingStatus =
        "Saving " + recipes.length + " affected recipe(s)";

      let response = await Api.UpdateRecipe(recipes);

      for (let recipe of response.data) {
        let tmp = context.state.recipes.find(
          x => x.keyValue === recipe.keyValue
        );
        let recipeIndex = context.state.recipes.indexOf(tmp);
        recipe = Object.assign(context.state.recipes[recipeIndex], recipe);
        context.state.recipes.sort(compare);
      }

      context.state.saving = false;
      context.state.savingStatus = "";

      return response.data;
    },
    async SaveRecipe(context, recipe) {
      console.log("Enter SaveData for " + recipe.name);
      context.state.savingStatus = "Saving recipe " + recipe.name;
      context.state.saving = true;
      let recipeIndex = -1;

      if (recipe.keyValue === "TempKey") {
        //Add
        let response = await Api.NewRecipe([recipe]);
        recipe = response.data[0];
        console.log("ADding");
        console.log(response.data);
        context.state.recipes.push(recipe);
        context.state.recipes.sort(compare);
        recipeIndex = context.state.recipes.indexOf(recipe);
      } // Update
      else {
        let response = await Api.UpdateRecipe([recipe]);
        recipe = response.data[0];
        let tmp = context.state.recipes.find(
          x => x.keyValue === recipe.keyValue
        );
        recipeIndex = context.state.recipes.indexOf(tmp);
        recipe = Object.assign(context.state.recipes[recipeIndex], recipe);
        context.state.recipes.sort(compare);
        recipeIndex = context.state.recipes.indexOf(recipe);
      }

      context.state.recipe = recipe;
      context.state.saving = false;
      context.state.savingStatus = "";
      return recipeIndex;
    },
    async UpdateAffectedRecipes(context, item) {
      console.log("UpdateAffectedRecipes because of " + item.name);

      let affectedRecipes = await context.dispatch(
        "UsedInRecipes",
        item.keyValue
      );
      console.log(
        "UpdateAffectedRecipes:Affected Recipes = " + affectedRecipes.length
      );

      let recipesUpdated = affectedRecipes;

      console.log(recipesUpdated);

      for (const recipe of affectedRecipes) {
        let affectedIngredient = recipe.ingredients.find(
          ingredient => ingredient.keyValue === item.keyValue
        );
        if (affectedIngredient.itemType === "recipe")
          affectedIngredient.kgCost = item.kgCost;
        else if (affectedIngredient.itemType === "ingredient")
          affectedIngredient.kgCost = item.kgPrice;
        else if (affectedIngredient.itemType === "packaging")
          affectedIngredient.kgCost = item.unitPrice;

        affectedIngredient.name = item.name;

        await context.dispatch("UpdateLineItems", recipe);
        affectedRecipes = await context.dispatch(
          "UpdateAffectedRecipes",
          recipe
        );

        recipesUpdated = recipesUpdated.concat(affectedRecipes);
      }

      console.log(
        "UpdateAffectedRecipes:recipesUpdated = " + recipesUpdated.length
      );

      return recipesUpdated;
    },
    async DeleteRecipe(context, recipe) {
      context.state.saving = true;
      console.log("Entering delete");
      console.log(recipe);
      await Api.DeleteRecipe(recipe);

      let tmp = context.state.recipes.find(x => x.keyValue === recipe.keyValue);
      let recipeIndex = context.state.recipes.indexOf(tmp);

      context.state.recipes.splice(recipeIndex, 1);
      if (recipeIndex > 0) recipeIndex--;
      else recipeIndex = 0;
      context.state.saving = false;
      context.state.alert.type = "success";
      context.state.alert.message = "Recipe deleted successfully";
      context.state.alert.show = true;

      context.state.recipe = context.state.recipes[recipeIndex];
      console.log(context.state.recipe);
    },
    async LoadData(context, forceRefresh = false) {
      if (context.state.jwt === "") context.dispatch("GetLocalJwt");

      context.state.loading = true;
      if (forceRefresh) {
        context.state.ingredients = [];
        context.state.packaging = [];
        context.state.recipes = [];
      }
      if (context.state.recipes.length === 0) {
        let response = await Api.GetData();
        let ingredients = new Array().concat(
          response.data.filter(x => x.itemType === "ingredient")
        );
        let packaging = new Array().concat(
          response.data.filter(x => x.itemType === "packaging")
        );
        let recipes = new Array().concat(
          response.data.filter(x => x.itemType === "recipe")
        );
        context.commit("SET_INGREDIENTS", ingredients);
        context.commit("SET_PACKAGING", packaging);
        context.commit("SET_RECIPES", recipes);
      }
      context.state.loading = false;
    },
    ShowAlert(context, alert) {
      context.state.alert.type = alert.type;
      context.state.alert.message = alert.message;
      context.state.alert.timeout = alert.timeout;
      context.state.alert.show = true;
    }
  }
});
