import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Login from "../views/LogIn.vue";
import Ingredients from "../views/Ingredients.vue";
import Packaging from "../views/Packaging.vue";
import Recipes from "../views/Recipes.vue";
import Recipe from "../views/Recipe.vue";
import RecipeBook from "../views/RecipeBook.vue";
import RecipeBookPage from "../views/RecipeBookPage.vue";
import store from "../store/index.js";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/login",
    name: "Login",
    component: Login
  },
  {
    path: "/ingredients",
    name: "Ingredients",
    component: Ingredients
  },
  {
    path: "/packaging",
    name: "Packaging",
    component: Packaging
  },
  {
    path: "/recipes",
    name: "Recipes",
    component: Recipes
  },
  {
    path: "/recipes/recipe",
    component: Recipe,
    name: "Recipe"
  },
  {
    path: "/recipebook/page",
    component: RecipeBookPage,
    name: "RecipeBookPage"
  },

  {
    path: "/recipebook",
    name: "RecipeBook",
    component: RecipeBook
  }
];

const router = new VueRouter({
  routes,
  mode: "history"
});

router.beforeEach(async (to, from, next) => {
  if (!store.state.user.loggedIn) await store.dispatch("GetLocalJwt");
  if (to.name !== "Login" && !store.state.user.loggedIn)
    next({ name: "Login" });
  else next();
});

export default router;
