<template>
  <div>
    <v-app id="inspire">
      <v-snackbar
        app
        top
        :color="$store.state.alert.type"
        v-model="$store.state.alert.show"
        :timeout="$store.state.alert.timeout"
      >
        {{ $store.state.alert.message }}
      </v-snackbar>
      <v-app-bar
        height="55"
        app
        elevation="0"
        clipped-left
        color="grey darken-3"
      >
        <div v-if="!$store.state.printFriendly">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                :disabled="!$store.state.user.loggedIn"
                v-bind="attrs"
                v-on="on"
                icon
                to="/"
                ><v-icon color="white">mdi-home</v-icon></v-btn
              >
            </template>
            <span>Home</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                :disabled="!$store.state.user.loggedIn"
                v-bind="attrs"
                v-on="on"
                icon
                to="/ingredients"
                ><v-icon color="white">mdi-spoon-sugar</v-icon></v-btn
              >
            </template>
            <span>Ingredients</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                :disabled="!$store.state.user.loggedIn"
                v-bind="attrs"
                v-on="on"
                icon
                to="/packaging"
                ><v-icon color="white">mdi-package-variant</v-icon></v-btn
              >
            </template>
            <span>Packaging</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                :disabled="!$store.state.user.loggedIn"
                v-bind="attrs"
                v-on="on"
                icon
                to="/recipes"
                ><v-icon color="white">mdi-baguette</v-icon></v-btn
              >
            </template>
            <span>Recipes</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                :disabled="!$store.state.user.loggedIn"
                v-bind="attrs"
                v-on="on"
                icon
                to="/recipebook"
                ><v-icon color="white"
                  >mdi-book-open-page-variant-outline</v-icon
                ></v-btn
              >
            </template>
            <span>Recipe Book</span>
          </v-tooltip>
        </div>
        <v-spacer />
        <v-toolbar-title class="white--text"
          >Namib Bakery Recipe Management v1.21
        </v-toolbar-title>

        <v-spacer />

        <span class="caption white--text"
          >developed by <strong>heizlogic</strong>
        </span>
        <div v-if="$store.state.user.loggedIn">
          <v-avatar>
            <v-icon color="white">mdi-account</v-icon>
            {{ $store.state.user.name }}
          </v-avatar>
        </div>
      </v-app-bar>
      <v-main class="grey lighten-1">
        <v-container fluid>
          <router-view></router-view>
        </v-container>
      </v-main>
    </v-app>
  </div>
</template>
<script>
export default {
  data: () => ({ drawer: true }),
  beforeCreate: async function () {
    await this.$store.dispatch("LoadData");
  },
};
</script>
<style lang="scss"></style>
